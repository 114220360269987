import { getCookie } from "../../utils";

/**
 * Our user interaction logging function that curently sends data to amplitude.
 *
 * @param {string} eventType name of the event being logged
 * @param {Object} data any additional data to be passed along with the event logging
 */
export const logUserInteraction = (eventType, extraData, omitSessionId = false) => {
    /**
     * TODO: Replace `omitSessionId` with a `slug` parameter that is used to
     * pass in the session id where needed. The session id is relevant only
     * in the context of chats, wherein `sessionId` is the coaching session
     * `hashid`.
     *
     * The `/events/ux/<slug:hashid>` view expects a valid hashid
     * and not any slug. Thus, this reliance on `pathname.split("/")[2]` breaks
     * anytime we use this function from any url that has a second path element,
     * like `/coach/onboarding`. For now, `omitSessionId` was added to bypass
     * this implicit assumption, but this is brittle and instead we should
     * refactor this util so that the slug is passed explicitly when needed.
     */
    const sessionId = window.location.pathname.split("/")[2]; // Get the session ID from the URL
    const logUrl = !omitSessionId && !!sessionId ? `/events/ux/${sessionId}` : "/events/ux/";
    const data = {
        event_type: eventType,
        extra_data: extraData,
        path: window.location.pathname,
    };
    return fetch(logUrl, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            "X-CSRFToken": getCookie("csrftoken"),
        },
        body: JSON.stringify(data),
    });
};

/**
 * Error logging function for the UI, current logs to Sentry.
 *
 * @param {Error} error error object that needs to be logged
 */
export const logError = (error) => {
    if (window?.Sentry) {
        window.Sentry.captureException(error);
    }
};
